export function initMasonry() {
  const grid = document.getElementsByClassName("masonry")[0];
  const allItems = document.getElementsByClassName("brick");

  grid.classList.add('auto-rows-[20px]')

  function resizeGridItem(item){
    const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    const rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = "span "+rowSpan;
  }

  function resizeAllGridItems(){
    for(let x=0;x<allItems.length;x++){
      resizeGridItem(allItems[x]);
    }
  }

  window.onload = resizeAllGridItems();
  window.addEventListener("resize", resizeAllGridItems);
}