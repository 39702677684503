export function initOverlay() {
  const toggleButton = document.querySelector('[data-overlay-id]');

  const overlay = document.getElementById(toggleButton.dataset.overlayId)

  const overlayCloseButton = overlay.querySelector('button[data-close]');

  function closeOverlay(o) {
    o.classList.add('w-0');
    o.classList.add('h-0');
    o.classList.remove('w-full');
    o.classList.remove('h-full');
  }

  overlayCloseButton.addEventListener('click', () => {
    closeOverlay(overlay)
  })

  document.addEventListener('keydown', (e) => {
    if (e.key === "Escape") {
      closeOverlay(overlay)
    }
  })

  toggleButton.addEventListener('click', () => {
    overlay.classList.toggle('w-0');
    overlay.classList.toggle('h-0');
    overlay.classList.toggle('w-full');
    overlay.classList.toggle('h-full');
  })
}