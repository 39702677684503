import { initCollapsible } from "./collapsible";
import { count } from "./counters";
import { initGallery } from "./gallery";
import { initMasonry } from "./masonry";
import { initNavbar } from "./navbar";
import { initOverlay } from "./overlay";

initNavbar();
count();
initOverlay();
initCollapsible();
initGallery();
initMasonry();