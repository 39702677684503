export function initCollapsible() {
  const buttons = document.querySelectorAll('button[data-collapse]');

  for (const button of buttons) {
    const collapsibles = document.querySelectorAll(`[data-collapsible=${button.dataset.collapse}]`);
    const arrows = button.getElementsByTagName('svg');
    if (button.dataset.collapsibleLabelCollapsed) {
      button.textContent = button.dataset.collapsibleLabelCollapsed;
    }
    button.addEventListener('click', () => {
      if (button.dataset.collapsibleLabelCollapsed && button.dataset.collapsibleLabelOpen) {
        if (button.textContent == button.dataset.collapsibleLabelOpen) {
          button.textContent = button.dataset.collapsibleLabelCollapsed;
        } else if (button.textContent == button.dataset.collapsibleLabelCollapsed) {
          button.textContent = button.dataset.collapsibleLabelOpen;
        }
      }
      for (const arrow of arrows) {
        arrow.classList.toggle('rotate-180')
      }
      for (const collapsible of collapsibles) {
        collapsible.classList.toggle('collapsible-open')
        if (!collapsible.style.maxHeight) {
          collapsible.style.maxHeight = `${collapsible.firstElementChild.offsetHeight}px`;
        } else {
          collapsible.style.maxHeight = null;
        }
      }
    })
  }
}