import baguetteBox from 'baguettebox.js';
import Glide from '@glidejs/glide';

export function initGallery() {
  baguetteBox.run('.gallery');

  const slider = new Glide('.glide', {
    gap: 30,
    perView: 1,
    hoverpause: true,
    autoplay: 10000,
    focusAt: 'center',
    breakpoints: {
      1800: {
        perView: 1
      }
    }
  }).mount()

  const counter = document.querySelector('.glide .glide__slide__number')

  slider.on('move', () => {
    counter.textContent = slider.index + 1
  })

  const thumbnails = document.querySelectorAll('.glide button.preview')

  for (const thumbnail of thumbnails) {
    thumbnail.addEventListener('click', () => {
      window.location.hash = 'photogallery'
    })
  }
}