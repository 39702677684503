export function initNavbar() {
  const range = (start, stop, step = 1) =>
    Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

  const navbar = document.querySelector('nav');

  function updateNavbarColor(entries, observer) {
    const intersectionRatio = entries[0].intersectionRatio;
    let opacity = Math.min(1 - intersectionRatio, 0.75);
    if (intersectionRatio > 0.8) {
      opacity = 0;
    }
    navbar.style.setProperty('--tw-bg-opacity', opacity);
    if (intersectionRatio > 0.7) {
      navbar.classList.add('text-primary-900', 'shadow-transparent');
      navbar.classList.remove('text-white', 'shadow-primary-900');
    } else {
      navbar.classList.remove('text-primary-900', 'shadow-transparent');
      navbar.classList.add('text-white', 'shadow-primary-900');
    }
  }

  let headerColorObserver = new IntersectionObserver(updateNavbarColor, {
    root: null,
    rootMargin: '0px',
    threshold: range(0.2, 0.9, 0.05)
  });

  headerColorObserver.observe(document.querySelector('header'));

  function updateActiveSection(entries) {
    let highestIntersection = 0;
    let activeLink = null;
    for (const sectionEntry of entries) {
      const navLink = navbar.querySelector(`a[href="#${sectionEntry.target.id}"]`);
      if (sectionEntry.isIntersecting) {
        if (sectionEntry.intersectionRect.height >= highestIntersection) {
          activeLink = navLink;
        }
      }
      navLink.classList.remove('after:scale-x-50')
      navLink.classList.add('after:scale-x-0')
    }
    if (activeLink) {
      activeLink.classList.add('after:scale-x-50')
      activeLink.classList.remove('after:scale-x-0')
    }
  }

  let sectionObserver = new IntersectionObserver(updateActiveSection, {
    root: null,
    rootMargin: '-50% 0px',
    threshold: 0
  })

  for (const target of document.querySelectorAll('section')) {
    sectionObserver.observe(target)
  }
}